import ICustomerServices from './Impl/ICustomerServices';
import { CustomerSearchConditions } from '../models/searchConditionsModel';
import CustomerSearchRepository from '../repositories/CustomerSearchRepository';
import CustomerDetails from '../models/CustomerDetails';
import { Customer, ContactAddressType } from '../models/Dto/CustomerSearchResultsDto';

export default class CustomerServices implements ICustomerServices {
    private readonly customerSearchRepository = new CustomerSearchRepository();

    public async searchCustomer(searchConditions: CustomerSearchConditions): Promise<CustomerDetails[]> {
        const res = await this.customerSearchRepository.searchCustomer(searchConditions);

        let customers: CustomerDetails[] = [];

        customers = res.results.map((customer) => {
            return this.getCustomerDetails(customer);
        });

        return customers;
    }

    private getCustomerDetails(customerDto: Customer) {
        let customer = new CustomerDetails();

        customer.CustomerName = customerDto.customerName;
        customer.PerorgCode = customerDto.perorgCode;
        if (!customerDto.dateOfBirth.startsWith('0001')) {
            customer.DateOfBirth = customerDto.dateOfBirth.slice(0, customerDto.dateOfBirth.indexOf('T'));
        }

        customerDto.contactAddresses
            ?.sort((a, b) => new Date(b.validatedDate).getTime() - new Date(a.validatedDate).getTime())
            .forEach((address) => {
                switch (address.contactAddressType) {
                    case ContactAddressType.NZNonStandard:
                        customer.NZNonStandard = address.displayAddress;
                        break;
                    case ContactAddressType.NZUrban:
                        customer.NZUrban = address.displayAddress;
                        break;
                    case ContactAddressType.Phone:
                        customer.Phone = address.displayAddress;
                        break;
                    case ContactAddressType.Fax:
                        customer.Fax = address.displayAddress;
                        break;
                    case ContactAddressType.Cellular:
                        customer.Cellular = address.displayAddress;
                        break;
                    case ContactAddressType.DDI:
                        customer.DDI = address.displayAddress;
                        break;
                    case ContactAddressType.AfterHours:
                        customer.AfterHours = address.displayAddress;
                        break;
                    case ContactAddressType.Alternative:
                        customer.Alternative = address.displayAddress;
                        break;
                    case ContactAddressType.AlternativePhone:
                        customer.AlternativePhone = address.displayAddress;
                        break;
                    case ContactAddressType.Delivery:
                        customer.Delivery = address.displayAddress;
                        break;
                    case ContactAddressType.DirectDial:
                        customer.DirectDial = address.displayAddress;
                        break;
                    case ContactAddressType.EMail:
                        customer.EMail = address.displayAddress;
                        break;
                    case ContactAddressType.Email:
                        customer.Email = address.displayAddress;
                        break;
                    case ContactAddressType.Mailing:
                        customer.Mailing = address.displayAddress;
                        break;
                    case ContactAddressType.NZPOBox:
                        customer.NZPOBox = address.displayAddress;
                        break;
                    case ContactAddressType.NZPrivateBag:
                        customer.NZPrivateBag = address.displayAddress;
                        break;
                    case ContactAddressType.NZRural:
                        customer.NZRural = address.displayAddress;
                        break;
                    case ContactAddressType.Overseas:
                        customer.Overseas = address.displayAddress;
                        break;
                    case ContactAddressType.Pager:
                        customer.Pager = address.displayAddress;
                        break;
                    case ContactAddressType.Physical:
                        customer.Physical = address.displayAddress;
                        break;
                    case ContactAddressType.PhysicalLocation:
                        customer.PhysicalLocation = address.displayAddress;
                        break;
                    case ContactAddressType.Postal:
                        customer.Postal = address.displayAddress;
                        break;
                }
            });

        return customer;
    }
}
