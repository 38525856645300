import CircularProgress from '@mui/material/CircularProgress';
import { Modal, Button } from 'react-bootstrap';

interface PopupModal {
    messageHeader: string;
    messageBody?: string;
    messageBodyContent?: React.ReactNode;
    onHide(): void;
    hasConfirmButton?: boolean;
    onConfirm?(): void;
    modalStatus: boolean;
    isUpdating?: boolean;
}

export default function PopUpModal(props: Readonly<PopupModal>) {
    if (props.isUpdating) {
        return (
            <Modal show={props.modalStatus} aria-labelledby='pop up modal' centered>
                <Modal.Body>
                    <CircularProgress size={64} style={{ display: 'flex', margin: '12rem auto' }} />
                </Modal.Body>
            </Modal>
        );
    } else {
        return (
            <Modal show={props.modalStatus} aria-labelledby='pop up modal' centered>
                <Modal.Body>
                    <h4>{props.messageHeader}</h4>
                    {props.messageBody ? <h5>{props.messageBody}</h5> : null}
                    {props.messageBodyContent}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                    {props.hasConfirmButton && props.onConfirm ? <Button onClick={props.onConfirm}>Confirm</Button> : null}
                </Modal.Footer>
            </Modal>
        );
    }
}
