import { ChangeEvent, useEffect, useState } from 'react';
import { Row, Container, FormGroup, Form } from 'react-bootstrap';
import PopUpModal from '../components/PopUpModal';
import SrItemService from '../services/SrItemService';
import SrItemAttributeService from '../services/SrItemAttributeService';
import SrItemDetails from '../models/SrItemDetails';
import ReadyForMojInfringementsTable from '../components/ExportForMojPage/ReadyForMojInfringementsTable';
import _ from 'lodash';
import { DataAccess, FunctionAccess, HasAccess, RenderIfHasAccess } from '../auth/RoleAccess';
import { SrAttributeType } from '../models/Dto/attributeTypeResultsDto';
import { SrItemType, SrTypeExtension } from '../models/SrType';

const srItemsService = new SrItemService();
const srItemAttributeService = new SrItemAttributeService();

export default function ExportForMoj() {
    const isPublicHealthTeam = HasAccess(FunctionAccess.Export, DataAccess.Dog);
    const [isLoading, setIsLoading] = useState(false);

    const [infringementType, setInfringementType] = useState('');
    const [srTypeExtensionId, setSrTypeExtensionId] = useState('');

    const [srItemAttributes, setSrItemAttributes] = useState<SrAttributeType[]>([]);
    const [srItems, setSrItems] = useState<SrItemDetails[] | null>(null);
    const [filteredSrItems, setFilteredSrItems] = useState<SrItemDetails[]>([]);

    const [modalShow, setModalShow] = useState(false);
    const [modalHeader, setModalHeader] = useState('');
    const [modalMessge, setModalMessage] = useState('');

    const infringementTypeUpdated = async (el: ChangeEvent<HTMLSelectElement>) => {
        const newInfringementType = el.currentTarget.value;
        setIsLoading(true);
        setInfringementType(newInfringementType);
        filterBySrItemType(srItems, newInfringementType);
        if (newInfringementType === SrItemType.GeneralInfringement) {
            if (srTypeExtensionId === '') {
                setFilteredSrItems([]);
            } else {
                const srExtension = filterBySrTypeExtension(srItems, srTypeExtensionId);
                setFilteredSrItems(srExtension);
            }
        } else {
            setSrTypeExtensionId('');
        }
        if (newInfringementType !== '') {
            const srItemAttributes = await srItemAttributeService.getSrItemAttributeTypes(+newInfringementType);
            setSrItemAttributes(srItemAttributes);
        }
        setIsLoading(false);
    };

    const srTypeExtensionUpdated = (el: ChangeEvent<HTMLSelectElement>) => {
        const newSrTypeExtension = el.currentTarget.value;
        if (newSrTypeExtension === '') {
            setFilteredSrItems([]);
        } else {
            setSrTypeExtensionId(newSrTypeExtension);
            setFilteredSrItems(filterBySrTypeExtension(srItems, newSrTypeExtension));
        }
    };

    const filterBySrTypeExtension = (givenSrItems: SrItemDetails[] | null, extensionTypeId: string) => {
        return _.filter(givenSrItems, (srItem) => {
            return srItem.srTypeExtension === +extensionTypeId;
        });
    };

    const filterBySrItemType = (givenSrItems: SrItemDetails[] | null, infringementTypeId: string) => {
        const filteredInfringements = _.filter(givenSrItems, (srItem) => {
            return srItem.srItemTypeId === +infringementTypeId;
        });
        setFilteredSrItems(filteredInfringements);
    };

    const setDataBaseOnAdGroup = async (srItemsResults: SrItemDetails[]) => {
        if (isPublicHealthTeam) {
            setInfringementType(SrItemType.DogInfringement);
            filterBySrItemType(srItemsResults, SrItemType.DogInfringement);
            const srItemAttributes = await srItemAttributeService.getSrItemAttributeTypes(+SrItemType.DogInfringement);
            setSrItemAttributes(srItemAttributes);
        } else {
            setInfringementType(SrItemType.GeneralInfringement);
            setSrTypeExtensionId(SrTypeExtension.Building);
            setFilteredSrItems(filterBySrTypeExtension(srItemsResults, SrTypeExtension.Building));
            const srItemAttributes = await srItemAttributeService.getSrItemAttributeTypes(+SrItemType.GeneralInfringement);
            setSrItemAttributes(srItemAttributes);
        }
    };

    const loadInfringements = async () => {
        setIsLoading(true);
        try {
            setSrItems([]);
            const srItemsResults = await srItemsService.searchSrItems({ Status: 'Court Action to be Taken' });
            setSrItems(srItemsResults);
            setDataBaseOnAdGroup(srItemsResults);
        } catch {
            setModalShow(true);
            setModalHeader('Server side error');
            setModalMessage('Please report to the development team with this issue.');
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadInfringements();
    }, []);

    return (
        <Container fluid='sm' style={{ flexGrow: 2 }}>
            <div className='page-header'>
                <h4 className='header-title'>Export For MOJ</h4>
            </div>
            <hr />
            {!isLoading ? (
                <Row>
                    <FormGroup className='wcc-form-group col-4'>
                        <Form.Label>Infringements Type</Form.Label>
                        <Form.Select aria-label='Select an infringement' value={infringementType} onChange={infringementTypeUpdated}>
                            <option value=''></option>
                            <RenderIfHasAccess requiresData={DataAccess.Dog}>
                                <option value={SrItemType.DogInfringement}>Dog Infringement</option>
                            </RenderIfHasAccess>
                            <RenderIfHasAccess requiresData={DataAccess.Generic}>
                                <option value={SrItemType.GeneralInfringement}>General Infringement</option>
                            </RenderIfHasAccess>
                        </Form.Select>
                    </FormGroup>
                    {infringementType === SrItemType.GeneralInfringement ? (
                        <FormGroup className='wcc-form-group col-4'>
                            <Form.Label>Extension</Form.Label>
                            <Form.Select aria-label='Select an infringement extension' value={srTypeExtensionId} onChange={srTypeExtensionUpdated}>
                                <option value=''></option>
                                <RenderIfHasAccess requiresData={DataAccess.Building}>
                                    <option value={SrTypeExtension.Building}>Building</option>
                                </RenderIfHasAccess>
                                <RenderIfHasAccess requiresData={DataAccess.BWof}>
                                    <option value={SrTypeExtension.BWoF}>BWof</option>
                                </RenderIfHasAccess>
                                <RenderIfHasAccess requiresData={DataAccess.ResMgt}>
                                    <option value={SrTypeExtension.ResMgt}>Res Mgt</option>
                                </RenderIfHasAccess>
                                <RenderIfHasAccess requiresData={DataAccess.Litter}>
                                    <option value={SrTypeExtension.Litter}>Litter</option>
                                </RenderIfHasAccess>
                                <RenderIfHasAccess requiresData={DataAccess.Food}>
                                    <option value={SrTypeExtension.FoodAct14}>Food Act 14</option>
                                </RenderIfHasAccess>
                                <RenderIfHasAccess requiresData={DataAccess.Alcohol}>
                                    <option value={SrTypeExtension.Alcohol}>Alcohol</option>
                                </RenderIfHasAccess>
                            </Form.Select>
                        </FormGroup>
                    ) : null}
                </Row>
            ) : null}
            <br />
            <br />
            {srItems !== null ? (
                <ReadyForMojInfringementsTable
                    srItemDetails={filteredSrItems}
                    selectedInfringementType={infringementType}
                    selectedInfringementExtension={srTypeExtensionId}
                    srItemAttributes={srItemAttributes}
                    isLoading={isLoading}
                />
            ) : null}
            <PopUpModal modalStatus={modalShow} onHide={() => setModalShow(false)} messageHeader={modalHeader} messageBody={modalMessge} />
        </Container>
    );
}
