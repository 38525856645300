import { useState, MouseEvent, Fragment, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import SrResult from '../../models/SrResult';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Button, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import PopUpModal from '../PopUpModal';
import { CircularProgress } from '@mui/material';
import DogOwnerSrService from '../../services/DogOwnerSrService';
import { SrItemType } from '../../models/SrType';

const dogOwnerSrService = new DogOwnerSrService();

interface HeadCell {
    id: keyof SrResult;
    label: string;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'SrNumber',
        label: 'SR Number',
    },
    {
        id: 'CustomerName',
        label: 'Customer Name',
    },
    {
        id: 'Address',
        label: 'Address',
    },
    {
        id: 'SrType',
        label: 'SR Type',
    },
];

interface SrResultsTableProps {
    searchingState: boolean;
    srResults: SrResult[] | null;
}

export default function SrResultsTable(props: Readonly<SrResultsTableProps>) {
    const [isLoading, setIsLoading] = useState(false);

    const [selectedSr, setSelectedSr] = useState<SrResult>({ SrNumber: 0 });
    const navigator = useNavigate();
    const rowsPerPage = 10;
    const [page, setPage] = useState(0);

    const [modalShow, setModalShow] = useState(false);

    const handleClick = async (event: MouseEvent<unknown>, row: SrResult) => {
        setSelectedSr(row);
    };

    const isSelected = (srNumber: number) => selectedSr.SrNumber?.toString().indexOf(srNumber.toString()) !== -1;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const selectAndCreateInfringement = async (event: MouseEvent<unknown>, row: SrResult) => {
        setSelectedSr(row);
        if (row.SrType === 'Dog Owner') {
            setIsLoading(true);
            setModalShow(true);
            const reRegDogOwner = await dogOwnerSrService.getDogOwnerInfo(row.SrNumber);
            const ownerAndDogs = await dogOwnerSrService.searchOwnerAndDogDetails({ OwnerSrCode: row.SrNumber.toString() });
            setIsLoading(false);
            setModalShow(false);
            navigator('/create-service-request-item', {
                state: { srType: +SrItemType.DogInfringement, selectedSr: row, reRegDogOwner: reRegDogOwner, ownerAndDogsDetails: ownerAndDogs[0] },
            });
        } else {
            navigator('/create-service-request-item', { state: { srType: +SrItemType.GeneralInfringement, selectedSr: row } });
        }
    };

    useEffect(() => {
        setPage(0);
    }, [props.searchingState]);

    const srResutlsTable = () => {
        if (props.searchingState) {
            return <CircularProgress size={64} style={{ display: 'flex', margin: '12rem auto' }} />;
        } else if (!props.searchingState && props.srResults?.length === 0) {
            return (
                <Container className='empty-search-results'>
                    <h3>No result matches above criteria</h3>
                    <h4>
                        Try modify the criteria or <Link to='/create-service-request'>Create new Service Request</Link>
                    </h4>
                </Container>
            );
        } else if (props.srResults !== null) {
            return (
                <Paper>
                    <TableContainer>
                        <Table sx={{ minWidth: 750 }} aria-labelledby='sr search results'>
                            <TableHead>
                                <TableRow>
                                    {headCells.map((headCell) => (
                                        <TableCell key={headCell.id}>
                                            <TableSortLabel>{headCell.label}</TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {props.srResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                    const isItemSelected = isSelected(row.SrNumber);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row)}
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.SrNumber}
                                            selected={isItemSelected}
                                        >
                                            <TableCell component='th' id={labelId} scope='row'>
                                                <Button variant='link' onClick={(event) => selectAndCreateInfringement(event, row)}>
                                                    {row.SrNumber}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button variant='link' onClick={(event) => selectAndCreateInfringement(event, row)}>
                                                    {row.CustomerName}
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <Button variant='link' onClick={(event) => selectAndCreateInfringement(event, row)}>
                                                    {row.Address}
                                                </Button>
                                            </TableCell>
                                            <TableCell>{row.SrType}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component='div'
                        count={props.srResults.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </Paper>
            );
        }
    };

    return (
        <Fragment>
            {srResutlsTable()}
            <PopUpModal modalStatus={modalShow} isUpdating={isLoading} onHide={() => setModalShow(false)} messageHeader={''} />
        </Fragment>
    );
}
