import { Card, Col, Container, Navbar, Row } from 'react-bootstrap';
import Footer from '../components/Footer';
import WccLogo from '../assets/images/wcc-logo.png';
import { AuthContext } from '../auth/AuthContext';
import { FunctionAccess, HasAccess } from '../auth/RoleAccess';
import { Helmet } from 'react-helmet-async';

const apps = [
    {
        title: 'Hapa',
        description: 'Infringement Management System',
        link: '/hapa',
        requiresAccess: FunctionAccess.All,
    },
];

// Basic landing page until this repo is merged into the monorepo
export default function InternalAppsLanding() {
    return (
        <div className='page-layout'>
            <Helmet>
                <title>WCC - Internal Apps</title>
            </Helmet>

            <Navbar className='wcc-nav-bar' style={{ width: '100%' }}>
                <Container>
                    <Navbar.Brand>
                        <img alt='Wellington City Council' src={WccLogo} width='192' height='48' />
                        <h1 style={{ marginTop: '2rem' }}>Internal Apps</h1>
                    </Navbar.Brand>
                    <div style={{ marginLeft: 'auto' }}>
                        <AuthContext.Consumer>{(authInfo) => authInfo.userEmail}</AuthContext.Consumer>
                    </div>
                </Container>
            </Navbar>

            <Container fluid='sm' style={{ flexGrow: '2', padding: '2rem' }}>
                <Row xs={1} md={2} className='g-4'>
                    {apps
                        .filter((x) => HasAccess(x.requiresAccess))
                        .map((app) => (
                            <Col key={app.title}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>{app.title}</Card.Title>
                                        <Card.Text>{app.description}</Card.Text>
                                        <Card.Link href={app.link}>View</Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                </Row>
            </Container>

            <Footer />
        </div>
    );
}

// Update title (page)
// Hide nav?
// Add route
