import { Container, Form, FormGroup, Row, Button } from 'react-bootstrap';
import { useState, ChangeEvent } from 'react';
import SrItemService from '../services/SrItemService';
import SrItemSearchResultsTable from '../components/SearchPage/srItemSearchResultsTable';
import PopUpModal from '../components/PopUpModal';
import SrItemDetails from '../models/SrItemDetails';
import { SearchSrItemConditions } from '../models/searchConditionsModel';
import { format } from 'date-fns';
import { DataAccess, HasAccess, RenderIfHasAccess } from '../auth/RoleAccess';
import { SrItemType } from '../models/SrType';

const srItemService = new SrItemService();

export default function SearchInfringement() {
    const infringementTypes = [];
    if (HasAccess(undefined, DataAccess.Dog)) {
        infringementTypes.push({ srTypeItemId: SrItemType.DogInfringement, name: 'Dog Owner Infringement' });
    }
    if (HasAccess(undefined, DataAccess.Generic)) {
        infringementTypes.push({ srTypeItemId: SrItemType.GeneralInfringement, name: 'General Infringement' });
    }

    const [srTypeItemId, setSrTypeItemId] = useState(infringementTypes.length > 0 ? infringementTypes[0].srTypeItemId : '');
    const [srTypeExtensionId, setSrTypeExtensionId] = useState('');
    const [srItems, setSrItems] = useState<SrItemDetails[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [srNumber, setSrNumber] = useState('');
    const [createdFromDate, setCreatedFromDate] = useState('');
    const [createdToDate, setCreatedToDate] = useState('');
    const [srStatus, setSrStatus] = useState('');
    const [createdUser, setCreatedUser] = useState('');
    const [feeStatus, setFeeStatus] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [modalHeader, setModalHeader] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const minDate = '2010-01-01';
    const maxDate = format(new Date(), 'yyyy-MM-dd');

    const updateInputElement = (el: ChangeEvent<HTMLInputElement>) => {
        switch (el.currentTarget.id) {
            case 'srNumber':
                setSrNumber(el.currentTarget.value);
                break;
            case 'createFromDate':
                setCreatedFromDate(el.currentTarget.value);
                break;
            case 'createToDate':
                setCreatedToDate(el.currentTarget.value);
                break;
            case 'createdUser':
                setCreatedUser(el.currentTarget.value);
                break;
            default:
                break;
        }
    };

    const updateSelectElement = (el: ChangeEvent<HTMLSelectElement>) => {
        switch (el.currentTarget.id) {
            case 'srType':
                setSrTypeItemId(el.currentTarget.value);
                break;
            case 'srStatus':
                setSrStatus(el.currentTarget.value);
                break;
            case 'feeStatus':
                setFeeStatus(el.currentTarget.value);
                break;
            case 'srTypeExtension':
                setSrTypeExtensionId(el.currentTarget.value);
                break;
            default:
                break;
        }
    };

    const generateStatusDropdownList = () => {
        const statusList = require('../appData/dogInfringementStatus.json');
        const list = statusList.dogInfringementStatus;
        return list.map((item: any) => {
            return (
                <option key={item.srStatusType} value={item.srStatusDescription}>
                    {item.srStatusDescription}
                </option>
            );
        });
    };

    const clearSearchResults = () => {
        setSrNumber('');
        setSrTypeExtensionId('');
        setCreatedFromDate('');
        setCreatedToDate('');
        setSrStatus('');
        setCreatedUser('');
        setFeeStatus('');
        setSrItems(null);
    };

    const searchButtonClicked = async () => {
        let searchConditions: SearchSrItemConditions = {};
        if (srNumber) {
            searchConditions = { ...searchConditions, SrNumber: srNumber };
        }
        if (srTypeExtensionId) {
            searchConditions = { ...searchConditions, SrTypeExtension: srTypeExtensionId };
        }
        if (createdFromDate) {
            searchConditions = { ...searchConditions, DateCreatedFrom: createdFromDate };
        }
        if (createdToDate) {
            searchConditions = { ...searchConditions, DateCreatedTo: createdToDate };
        }
        if (srStatus) {
            searchConditions = { ...searchConditions, Status: srStatus };
        }
        if (createdUser) {
            searchConditions = { ...searchConditions, CreatedUser: createdUser };
        }
        if (feeStatus) {
            searchConditions = { ...searchConditions, FeeStatus: feeStatus };
        }
        if (srTypeItemId) {
            searchConditions = { ...searchConditions, SrItemTypes: srTypeItemId };
        } else {
            setModalShow(true);
            setModalHeader('Please choose an infringement type.');
            return;
        }

        if (Object.keys(searchConditions).length > 1) {
            setIsLoading(true);
            setSrItems([]);
            try {
                const srItems = await srItemService.searchSrItems(searchConditions);
                setSrItems(srItems);
                setIsLoading(false);
                setTimeout(() => {
                    window.scrollTo(0, 500);
                }, 50);
            } catch {
                setModalShow(true);
                setModalHeader('Server side error');
                setModalMessage('Please report to the development team with this issue.');
                setIsLoading(false);
            }
        } else {
            setModalShow(true);
            setModalHeader('Please enter a search value.');
        }
    };

    return (
        <Container fluid='sm' style={{ flexGrow: '2' }}>
            <div className='page-header'>
                <h4 className='header-title'>Search Infringements</h4>
            </div>
            <hr />
            <Row>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>Infringement Type</Form.Label>
                    <Form.Select id='srType' value={srTypeItemId} onChange={updateSelectElement}>
                        {infringementTypes.map((type) => {
                            return (
                                <option key={type.srTypeItemId} value={type.srTypeItemId}>
                                    {type.name}
                                </option>
                            );
                        })}
                    </Form.Select>
                </FormGroup>
                {srTypeItemId === '10036187' ? (
                    <FormGroup className='wcc-form-group col-4'>
                        <Form.Label>Extension</Form.Label>
                        <Form.Select
                            id='srTypeExtension'
                            aria-label='Select an infringement extension'
                            value={srTypeExtensionId}
                            onChange={updateSelectElement}
                        >
                            <option value=''></option>
                            <RenderIfHasAccess requiresData={DataAccess.Building}>
                                <option value='1012'>Building</option>
                            </RenderIfHasAccess>
                            <RenderIfHasAccess requiresData={DataAccess.BWof}>
                                <option value='1013'>BWof</option>
                            </RenderIfHasAccess>
                            <RenderIfHasAccess requiresData={DataAccess.ResMgt}>
                                <option value='1014'>Res Mgt</option>
                            </RenderIfHasAccess>
                            <RenderIfHasAccess requiresData={DataAccess.Litter}>
                                <option value='1015'>Litter</option>
                            </RenderIfHasAccess>
                            <RenderIfHasAccess requiresData={DataAccess.Food}>
                                <option value='1057'>Food Act 14</option>
                            </RenderIfHasAccess>
                            <RenderIfHasAccess requiresData={DataAccess.ResMgt}>
                                <option value='1065'>Pool</option>
                            </RenderIfHasAccess>
                            <RenderIfHasAccess requiresData={DataAccess.Litter}>
                                <option value='1066'>RMA Compliance</option>
                            </RenderIfHasAccess>
                            <RenderIfHasAccess requiresData={DataAccess.Alcohol}>
                                <option value='1067'>Alcohol</option>
                            </RenderIfHasAccess>
                        </Form.Select>
                    </FormGroup>
                ) : null}
            </Row>
            <Row>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>SR Number</Form.Label>
                    <Form.Control id='srNumber' type='number' value={srNumber} onChange={updateInputElement} />
                </FormGroup>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>Date Created From</Form.Label>
                    <Form.Control id='createFromDate' type='date' value={createdFromDate} min={minDate} max={maxDate} onChange={updateInputElement} />
                </FormGroup>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>Date Created To</Form.Label>
                    <Form.Control id='createToDate' type='date' value={createdToDate} min={minDate} max={maxDate} onChange={updateInputElement} />
                </FormGroup>
            </Row>
            <Row>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>Status</Form.Label>
                    <Form.Select id='srStatus' aria-label='Select a Status' value={srStatus} onChange={updateSelectElement}>
                        <option value='' key=''></option>
                        {generateStatusDropdownList()}
                    </Form.Select>
                </FormGroup>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>Created By</Form.Label>
                    <Form.Control id='createdUser' type='text' value={createdUser} onChange={updateInputElement} />
                </FormGroup>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>Is Paid</Form.Label>
                    <Form.Select id='feeStatus' aria-label='Select a Status' value={feeStatus} onChange={updateSelectElement}>
                        <option value='' key=''></option>
                        <option value='Paid'>Yes</option>
                        <option value='UnPaid'>No</option>
                    </Form.Select>
                </FormGroup>
            </Row>
            <Row className='button-group' style={{ marginBottom: '2rem' }}>
                <Button variant='outline-primary' onClick={clearSearchResults}>
                    Clear
                </Button>
                <Button onClick={searchButtonClicked}>Search</Button>
            </Row>
            <hr style={{ color: '#dee2e6' }} />
            {srItems !== null ? <SrItemSearchResultsTable formContent={srItems} isLoading={isLoading} /> : null}
            <PopUpModal modalStatus={modalShow} onHide={() => setModalShow(false)} messageHeader={modalHeader} messageBody={modalMessage} />
        </Container>
    );
}
