import { Fragment, useContext, useState, ChangeEvent, useEffect } from 'react';
import { Container, Paper, CircularProgress, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import SrItemDetails from '../../models/SrItemDetails';
import { Button, ListGroup, Row } from 'react-bootstrap';
import { MojExportCommand, InfringementType } from '../../models/MojExportCommand';
import InfringementService from '../../services/InfringementService';
import PopUpModal from '../PopUpModal';
import { AuthContext } from '../../auth/AuthContext';
import { SrAttributeType } from '../../models/Dto/attributeTypeResultsDto';
import ExportForMojHelper from '../../utils/exportForMojHelper';
import PreviewInfringementModal from '../ExportForMojPage/PreviewInfringementModal';
import SrItemService from '../../services/SrItemService';
import SrItemStatusCommand, {
    GeneralInfringementStatusType,
    GeneralInfringementStatusTypeExtension,
    DogOwnerSrStatusExtension,
    DogOwnerSrStatusType,
} from '../../models/Dto/srItemStatusCommand';
import { SrItemType, SrTypeExtension } from '../../models/SrType';

interface SrItemResultsTableProps {
    srItemDetails: SrItemDetails[];
    isLoading: boolean;
    srItemAttributes: SrAttributeType[];
    selectedInfringementType: string;
    selectedInfringementExtension?: string;
}

const infringementService = new InfringementService();
const exportForMojHelper = new ExportForMojHelper();
const srItemService = new SrItemService();

export default function ReadyForMojInfringementsTable(props: Readonly<SrItemResultsTableProps>) {
    const authInfo = useContext(AuthContext);
    const [selectedSrItemNumbers, setSelectedSrItemNumbers] = useState<string[]>([]);

    const [srItemDetails, setSrItemDetails] = useState<SrItemDetails[]>([]);
    const [previewSrItem, setPreviewSrItem] = useState<SrItemDetails>();
    const [previewSrItemModal, setPreviewSrItemModal] = useState(false);

    const rowsPerPage = 10;
    const [page, setPage] = useState(0);

    const [isProcessing, setIsProcessing] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalHeader, setModalHeader] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [modalMessageContent, setModalMessageContent] = useState(<></>);
    const [hasConfirmButton, setHasConfirmButton] = useState(false);

    const changePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const selectSrItem = (srItemNumber: SrItemDetails) => {
        const selectedIndex = selectedSrItemNumbers.indexOf(srItemNumber.srItem.toString());
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedSrItemNumbers, srItemNumber.srItem.toString());
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedSrItemNumbers.slice(1));
        } else if (selectedIndex === selectedSrItemNumbers.length - 1) {
            newSelected = newSelected.concat(selectedSrItemNumbers.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selectedSrItemNumbers.slice(0, selectedIndex), selectedSrItemNumbers.slice(selectedIndex + 1));
        }

        setSelectedSrItemNumbers(newSelected);
    };

    const srTypeDescription = (srItemTypeId: number, srTypeExtension: number) => {
        if (srItemTypeId === +SrItemType.GeneralInfringement && srTypeExtension === +SrTypeExtension.Building) {
            return 'Building Infringement';
        } else if (srItemTypeId === +SrItemType.GeneralInfringement && srTypeExtension === +SrTypeExtension.BWoF) {
            return 'BWof Infringement';
        } else if (srItemTypeId === +SrItemType.GeneralInfringement && srTypeExtension === +SrTypeExtension.ResMgt) {
            return 'Res Mgt Infringement';
        } else if (srItemTypeId === +SrItemType.GeneralInfringement && srTypeExtension === +SrTypeExtension.Litter) {
            return 'Litter Infringement';
        } else if (srItemTypeId === +SrItemType.GeneralInfringement && srTypeExtension === +SrTypeExtension.FoodAct14) {
            return 'Food Act 14 Infringement';
        } else if (srItemTypeId === +SrItemType.DogInfringement) {
            return 'Dog Infringement';
        } else if (srItemTypeId === +SrItemType.GeneralInfringement && srTypeExtension === +SrTypeExtension.Alcohol) {
            return 'Alcohol Infringement';
        } else {
            return 'Unknown';
        }
    };

    const isSelected = (srItemNumber: number) => {
        if (selectedSrItemNumbers) {
            return selectedSrItemNumbers.toString().indexOf(srItemNumber.toString()) !== -1;
        }
    };

    const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = srItemDetails.map((n) => n.srItem.toString());
            setSelectedSrItemNumbers(newSelected);
            return;
        }
        setSelectedSrItemNumbers([]);
    };

    const viewInfringementDetails = (infringementDetails: SrItemDetails) => {
        setPreviewSrItem(infringementDetails);
        setPreviewSrItemModal(true);
    };

    const markAsExported = async () => {
        setModalShow(true);
        setIsProcessing(true);
        let prepareForUpdateItems: SrItemStatusCommand[] = [];
        if (props.selectedInfringementType === SrItemType.DogInfringement) {
            selectedSrItemNumbers.forEach(async (srItemNumber) => {
                const status: SrItemStatusCommand = {
                    srItem: +srItemNumber,
                    srStatusType: DogOwnerSrStatusType.LodgedWithCourt,
                    srStatusExtension: DogOwnerSrStatusExtension.Unpaid,
                    srStatusComment: 'Status updated by: ' + authInfo.userEmail + ' in Hapa.',
                };
                prepareForUpdateItems.push(status);
            });
        } else if (props.selectedInfringementType === SrItemType.GeneralInfringement && props.selectedInfringementExtension === '1067') {
            selectedSrItemNumbers.forEach(async (srItemNumber) => {
                const status: SrItemStatusCommand = {
                    srItem: +srItemNumber,
                    srStatusType: GeneralInfringementStatusType.Completed, // To do: set to compleate
                    srStatusComment: 'Status updated by: ' + authInfo.userEmail + ' in Hapa.',
                };
                prepareForUpdateItems.push(status);
            });
        } else {
            selectedSrItemNumbers.forEach(async (srItemNumber) => {
                const status: SrItemStatusCommand = {
                    srItem: +srItemNumber,
                    srStatusType: GeneralInfringementStatusType.LodgedWithCourt,
                    srStatusExtension: GeneralInfringementStatusTypeExtension.Unpaid,
                    srStatusComment: 'Status updated by: ' + authInfo.userEmail + ' in Hapa.',
                };
                prepareForUpdateItems.push(status);
            });
        }

        const failedItems = await srItemService.batchUpdateSrItemStatus(prepareForUpdateItems);
        setIsProcessing(false);
        setHasConfirmButton(false);
        if (failedItems.length < 1) {
            setModalHeader('Status updated');
            setModalMessage('The status of selected infringement(s) has been updated');
            setModalMessageContent(<></>);
            setTimeout(() => {
                // eslint-disable-next-line no-restricted-globals
                location.reload();
            }, 1000);
        } else {
            const updatedItems = selectedSrItemNumbers.filter((srItemNumber) => {
                return !failedItems.includes(+srItemNumber);
            });
            const filteredResults = srItemDetails.filter((srItemDetail) => {
                return !updatedItems.includes(srItemDetail.srItem.toString());
            });
            setSrItemDetails(filteredResults);
            setModalHeader('Status failed to update');
            setModalMessage('');
            setModalMessageContent(
                <ListGroup style={{ maxHeight: '30rem', overflowY: 'auto' }}>
                    {failedItems.map((item: number) => {
                        return (
                            <ListGroup.Item key={item}>
                                {item} status failed to updated, please update this infringement in Teamwork Desktop App or contact the development team
                            </ListGroup.Item>
                        );
                    })}
                </ListGroup>
            );
        }
    };

    const exportForMoj = async () => {
        setModalShow(true);
        setIsProcessing(true);
        let selectedInfringementType = InfringementType.Unknown;
        if (props.selectedInfringementType === SrItemType.DogInfringement) {
            selectedInfringementType = InfringementType.Dog;
        } else if (props.selectedInfringementType === SrItemType.GeneralInfringement && props.selectedInfringementExtension) {
            switch (props.selectedInfringementExtension) {
                case SrTypeExtension.Building:
                    selectedInfringementType = InfringementType.Building;
                    break;
                case SrTypeExtension.BWoF:
                    selectedInfringementType = InfringementType.BWof;
                    break;
                case SrTypeExtension.ResMgt:
                    selectedInfringementType = InfringementType.ResMgt;
                    break;
                case SrTypeExtension.Litter:
                    selectedInfringementType = InfringementType.Litter;
                    break;
                case SrTypeExtension.FoodAct14:
                    selectedInfringementType = InfringementType.FoodAct14;
                    break;
                case SrTypeExtension.Alcohol:
                    selectedInfringementType = InfringementType.Alcohol;
                    break;
                default:
                    selectedInfringementType = InfringementType.Unknown;
                    break;
            }
        }
        await exportForMojHelper.setupMojRequiredAttributes(props.srItemAttributes, srItemDetails, selectedSrItemNumbers, props.selectedInfringementExtension);
        let mojExportCommand: MojExportCommand = {
            requestedByEmail: authInfo.userEmail,
            infringementType: selectedInfringementType,
            ServiceRequestItems: [],
        };
        mojExportCommand.ServiceRequestItems = selectedSrItemNumbers;
        setTimeout(async () => {
            try {
                const xmlString = await infringementService.exportForMoj(mojExportCommand);
                await downloadFile(xmlString);
                setModalHeader('XML generated successfully');
                setModalMessage('');
                setModalMessageContent(<></>);
            } catch (error: any) {
                setIsProcessing(false);
                setModalHeader('Failed to generate XML');
                if (error.response.data.validationErrors.length > 0) {
                    setModalMessage(`There were ${error.response.data.validationErrors.length} validation errors.`);
                } else {
                    setModalMessage('');
                }
                setModalMessageContent(
                    <ListGroup style={{ maxHeight: '30rem', overflowY: 'auto' }}>
                        {error.response.data.validationErrors &&
                            error.response.data.validationErrors.map((error: string) => {
                                return <ListGroup.Item key={error}>{error}</ListGroup.Item>;
                            })}
                    </ListGroup>
                );
            }
            setIsProcessing(false);
        }, 1000);
    };

    const downloadFile = async (xml: string) => {
        const domParser = new DOMParser();
        const xmlContent = domParser.parseFromString(xml, 'text/xml');
        const fileName = xmlContent.getElementsByTagName('infringement-batch-id')[0].childNodes[0].nodeValue;

        const blob = new Blob(['\ufeff', xml], { type: 'text/xml;charset=utf-8' });
        const xmlFile = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('download', fileName!);
        link.href = xmlFile;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        setPage(0);
        setSelectedSrItemNumbers([]);
        if (props.srItemDetails) {
            setSrItemDetails(props.srItemDetails);
        }
    }, [props.isLoading, props.srItemDetails]);

    const tableHead = () => {
        const dogInfringementTableHeadersArray = ['SR Number', 'Notice Number', 'Form Number', 'Infringement Type', 'Description', 'Preview'];
        const generalInfringementTableHeaderArray = ['SR Number', 'Infringement Type', 'Description', 'Preview'];

        const headerCells = () => {
            if (props.selectedInfringementType === SrItemType.DogInfringement) {
                return dogInfringementTableHeadersArray.map((header) => {
                    return <TableCell key={header}>{header}</TableCell>;
                });
            } else {
                return generalInfringementTableHeaderArray.map((header) => {
                    return <TableCell key={header}>{header}</TableCell>;
                });
            }
        };
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding='checkbox'>
                        <Checkbox
                            color='primary'
                            indeterminate={selectedSrItemNumbers.length > 0 && selectedSrItemNumbers.length < srItemDetails.length}
                            checked={srItemDetails.length > 0 && selectedSrItemNumbers.length === srItemDetails.length}
                            onChange={handleSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all infringements',
                            }}
                        />
                    </TableCell>
                    {headerCells()}
                </TableRow>
            </TableHead>
        );
    };

    const tableBody = () => {
        return (
            <TableBody>
                {srItemDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    const isItemSelected = isSelected(row.srItem);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                        <TableRow
                            hover
                            onClick={(event) => selectSrItem(row)}
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.srItem}
                            selected={isItemSelected}
                        >
                            <TableCell padding='checkbox'>
                                <Checkbox
                                    color='primary'
                                    checked={isItemSelected}
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </TableCell>
                            <TableCell>{row.SrNumber}</TableCell>
                            {row.srItemTypeId === +SrItemType.DogInfringement ? (
                                <Fragment>
                                    <TableCell>
                                        {row.SrNumber}0{row.srItemCode}
                                    </TableCell>
                                    <TableCell>{row.srItem}</TableCell>
                                </Fragment>
                            ) : null}
                            <TableCell>{srTypeDescription(row.srItemTypeId, row.srTypeExtension)}</TableCell>
                            <TableCell>{row.Description}</TableCell>
                            <TableCell>
                                <Button
                                    variant='outline-secondary'
                                    size='sm'
                                    onClick={() => {
                                        viewInfringementDetails(row);
                                    }}
                                >
                                    Preview
                                </Button>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        );
    };

    return (
        <Fragment>
            <Paper>
                <TableContainer>
                    {props.isLoading ? (
                        <CircularProgress size={64} style={{ display: 'flex', margin: '12rem auto' }} />
                    ) : (
                        <Table sx={{ minWidth: 750 }} aria-labelledby='infringement search results'>
                            {tableHead()}
                            {tableBody()}
                        </Table>
                    )}
                    {srItemDetails.length === 0 && !props.isLoading ? (
                        <Container className='empty-search-results'>
                            <h3>No infringement matches above criteria</h3>
                        </Container>
                    ) : null}
                </TableContainer>
                {props.isLoading || srItemDetails.length === 0 ? null : (
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        component='div'
                        count={srItemDetails.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={changePage}
                    />
                )}
            </Paper>
            <Row className='button-group' style={{ marginBottom: '2rem' }}>
                <Button variant='outline-primary' onClick={markAsExported} disabled={selectedSrItemNumbers.length === 0}>
                    Update Status
                </Button>
                <Button onClick={exportForMoj} disabled={selectedSrItemNumbers.length === 0}>
                    Generate XML
                </Button>
            </Row>
            <PopUpModal
                modalStatus={modalShow}
                onHide={() => {
                    setModalShow(false);
                    setHasConfirmButton(false);
                }}
                isUpdating={isProcessing}
                messageHeader={modalHeader}
                messageBody={modalMessage}
                messageBodyContent={modalMessageContent}
                hasConfirmButton={hasConfirmButton}
                onConfirm={markAsExported}
            />
            <PreviewInfringementModal srItemDetails={previewSrItem} modalStatus={previewSrItemModal} onHide={() => setPreviewSrItemModal(false)} />
        </Fragment>
    );
}
