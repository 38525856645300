import { useState, useEffect, Fragment } from 'react';
import { Row, FormGroup, Form } from 'react-bootstrap';
import _ from 'lodash';
import { SrTypeDetails, SrExtension } from '../../models/SrType';
import * as InfringementTypes from '../../appData/infringementTypes.json';
import ServiceRequestCommand, { SrType } from '../../models/Dto/serviceRequestCommand';
import { StaticAuthContext } from '../../auth/AuthContext';
import { DataAccess } from '../../auth/RoleAccess';

interface SrForm {
    srCreationStatus: boolean;
    emitSrDetails(SrDetails: ServiceRequestCommand): void;
    emitSrTypeExtension(srTypeExtension: string): void;
}

export default function ServiceRequestForm(props: Readonly<SrForm>) {
    const infringementTypes: any = InfringementTypes;
    const srTypes: SrTypeDetails[] = infringementTypes.SrTypeDetails;

    const [srTypeId, setSrTypeId] = useState<number>();
    const [srTypeName, setSrTypeName] = useState<SrType>(SrType.DogOwner);
    const [srExtensionOptions, setSrExtensionOptions] = useState<SrExtension[]>([]);
    const [srExtension, setSrExtension] = useState<number | undefined>(undefined);
    const [srDescription, setSrDescription] = useState('');
    const [srComment, setSrComment] = useState('');
    const [srAddressNumber, setSrAddressNumber] = useState('');
    const [srAddressName, setSrAddressName] = useState('');
    const [locationComment, setLocationComment] = useState('');

    const generateDropdownList = () => {
        const allowedSrTypes: any[] = [];

        const authInfo = StaticAuthContext;
        if (authInfo.dataAccess & DataAccess.Dog) {
            const dogSrType = srTypes.find((x) => x.srTypeCode === 21);
            allowedSrTypes.push(dogSrType);
        }
        if (authInfo.dataAccess & DataAccess.Generic) {
            const genericSrType = srTypes.find((x) => x.srTypeCode === 5016034);
            allowedSrTypes.push(genericSrType);
        }

        return srTypes.map((srType) => {
            return (
                <option key={srType.srDescription} value={srType.srTypeName}>
                    {srType.srDescription}
                </option>
            );
        });
    };

    const extensionDropdownList = (extensions: SrExtension[]) => {
        return extensions.map((extension) => {
            return (
                <option key={extension.srTypeExtension} value={extension.srTypeExtension}>
                    {extension.extensionDescription}
                </option>
            );
        });
    };

    const updateSrType = (element: React.ChangeEvent<HTMLSelectElement>) => {
        const srItem = _.find(srTypes, (srType) => {
            return srType.srTypeName === element.currentTarget.value;
        });
        if (srItem) {
            setSrTypeId(srItem.defaultSrItemTypeId);
            setSrTypeName(srItem.srTypeName as SrType);
            if (srItem?.extensions) {
                setSrExtensionOptions(srItem.extensions);
                setSrExtension(srItem.extensions[0].srTypeExtension);
            } else {
                setSrExtensionOptions([]);
                setSrExtension(undefined);
            }
        }
    };

    const updateSrExtension = (element: React.ChangeEvent<HTMLSelectElement>) => {
        setSrExtension(+element.currentTarget.value);
        props.emitSrTypeExtension(element.currentTarget.value);
    };

    const updateSrDescription = (element: React.ChangeEvent<HTMLInputElement>) => {
        setSrDescription(element.currentTarget.value);
    };

    const updateSrComment = (element: React.ChangeEvent<HTMLInputElement>) => {
        setSrComment(element.currentTarget.value);
    };

    const updateAddressNumber = (element: React.ChangeEvent<HTMLInputElement>) => {
        setSrAddressNumber(element.currentTarget.value);
    };

    const updateAddressName = (element: React.ChangeEvent<HTMLInputElement>) => {
        setSrAddressName(element.currentTarget.value);
    };

    const updateLocationComment = (element: React.ChangeEvent<HTMLInputElement>) => {
        setLocationComment(element.currentTarget.value);
    };

    const SrDetails = () => {
        let srDetails: ServiceRequestCommand = { srType: SrType.NotSet };
        if (srTypeName) {
            const typeName = { srType: srTypeName };
            srDetails = { ...srDetails, ...typeName };
        }
        if (srTypeId) {
            const typeCode = { srItemTypeId: srTypeId };
            srDetails = { ...srDetails, ...typeCode };
        }
        if (srExtension) {
            const extension = { srTypeExtension: +srExtension };
            srDetails = { ...srDetails, ...extension };
        }
        if (srDescription) {
            const description = { description: srDescription };
            srDetails = { ...srDetails, ...description };
        }
        if (srComment) {
            const comment = { comment: srComment };
            srDetails = { ...srDetails, ...comment };
        }
        if (srAddressNumber) {
            const addressNumber = { locationNumber: srAddressNumber };
            srDetails = { ...srDetails, ...addressNumber };
        }
        if (srAddressName) {
            const addressName = { locationStreet: srAddressName };
            srDetails = { ...srDetails, ...addressName };
        }
        if (locationComment) {
            const lctComment = { locationComment: locationComment };
            srDetails = { ...srDetails, ...lctComment };
        }
        return srDetails;
    };

    useEffect(() => {
        props.emitSrDetails(SrDetails());
    }, [srTypeName, srExtension, srDescription, srComment, srAddressNumber, srAddressName, locationComment]);

    return (
        <Fragment>
            <Row>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>Service Request Type</Form.Label>
                    <Form.Select aria-label='Select an Act section' value={srTypeName} onChange={updateSrType} disabled={props.srCreationStatus}>
                        {generateDropdownList()}
                    </Form.Select>
                </FormGroup>
                {srExtensionOptions.length !== 0 ? (
                    <FormGroup className='wcc-form-group col-8'>
                        <Form.Label>Service Request Type Extension</Form.Label>
                        <Form.Select aria-label='Select an Act section' value={srExtension} onChange={updateSrExtension} disabled={props.srCreationStatus}>
                            {extensionDropdownList(srExtensionOptions)}
                        </Form.Select>
                    </FormGroup>
                ) : null}
            </Row>
            <FormGroup className='wcc-form-group'>
                <Form.Label>Description - Description of the Service Request</Form.Label>
                <Form.Control as='textarea' value={srDescription} rows={3} onChange={updateSrDescription} disabled={props.srCreationStatus} />
            </FormGroup>
            <FormGroup className='wcc-form-group'>
                <Form.Label>Comment - General comment for Service Request</Form.Label>
                <Form.Control as='textarea' value={srComment} rows={3} onChange={updateSrComment} disabled={props.srCreationStatus} />
            </FormGroup>
            <Row>
                <FormGroup className='wcc-form-group col-4'>
                    <Form.Label>Street No</Form.Label>
                    <Form.Control type='number' value={srAddressNumber} onChange={updateAddressNumber} disabled={props.srCreationStatus} />
                </FormGroup>
                <FormGroup className='wcc-form-group col-8'>
                    <Form.Label>Address</Form.Label>
                    <Form.Control type='text' value={srAddressName} onChange={updateAddressName} disabled={props.srCreationStatus} />
                </FormGroup>
            </Row>
            <FormGroup className='wcc-form-group'>
                <Form.Label>Location Comment</Form.Label>
                <Form.Control as='textarea' value={locationComment} rows={3} onChange={updateLocationComment} disabled={props.srCreationStatus} />
            </FormGroup>
        </Fragment>
    );
}
