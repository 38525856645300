import { ChangeEvent, useState } from 'react';
import { Form, FormGroup, Row, Button } from 'react-bootstrap';
import { DataAccess, RenderIfHasAccess } from '../../auth/RoleAccess';
import { SearchSrConditions } from '../../models/searchConditionsModel';
import PopUpModal from '../PopUpModal';

interface SearchSrProp {
    searchButtonClicked(searchConditions: SearchSrConditions, srType: string): void;
    clearSearchResults(): void;
}

export default function SearchExistingSr(props: Readonly<SearchSrProp>) {
    const [selectedSrType, setSelectedSrType] = useState('21');
    const [customerName, setCustomerName] = useState('');
    const [address, setAddress] = useState('');
    const [srNumber, setSrNumber] = useState('');

    const [modalShow, setModalShow] = useState(false);
    const [modalHeader, setModalHeader] = useState('');

    const updateInfringementTypeValue = (element: ChangeEvent<HTMLSelectElement>) => {
        setSelectedSrType(element.currentTarget.value);
    };

    const updateInputValue = (el: ChangeEvent<HTMLInputElement>) => {
        switch (el.currentTarget.id) {
            case 'customerName':
                setCustomerName(el.currentTarget.value);
                break;
            case 'srNumber':
                setSrNumber(el.currentTarget.value);
                break;
            case 'customerAddress':
                setAddress(el.currentTarget.value);
                break;
            default:
                return;
        }
    };

    const clearSearchResults = () => {
        setCustomerName('');
        setAddress('');
        setSrNumber('');
        props.clearSearchResults();
    };

    const searchSrItem = async () => {
        let searchValues: SearchSrConditions = {};
        if (customerName !== '') {
            searchValues = { ...searchValues, ...{ customerName: customerName } };
        }
        if (address !== '') {
            searchValues = { ...searchValues, ...{ address: address } };
        }
        if (srNumber !== '') {
            searchValues = { ...searchValues, ...{ srCode: srNumber } };
        }
        searchValues = { ...searchValues, ...{ srTypes: selectedSrType } };
        if (Object.keys(searchValues).length !== 1) {
            props.searchButtonClicked(searchValues, selectedSrType);
        } else {
            setModalShow(true);
            setModalHeader('Please enter a search value.');
        }
    };

    return (
        <div className='search-service-request'>
            <Row>
                <FormGroup className='wcc-form-group col-3'>
                    <Form.Label>Infringement Type</Form.Label>
                    <Form.Select aria-label='Select an Act section' value={selectedSrType} onChange={updateInfringementTypeValue}>
                        <RenderIfHasAccess requiresData={DataAccess.Dog}>
                            <option value='21'>Dog Owner Infringement</option>
                        </RenderIfHasAccess>
                        <RenderIfHasAccess requiresData={DataAccess.Generic}>
                            <option value='5016034'>General Infringement</option>
                        </RenderIfHasAccess>
                    </Form.Select>
                </FormGroup>
                <FormGroup className='wcc-form-group col-3'>
                    <Form.Label>Customer Name</Form.Label>
                    <Form.Control id='customerName' type='text' value={customerName} onChange={updateInputValue} />
                </FormGroup>
                <FormGroup className='wcc-form-group col-3'>
                    <Form.Label>Customer Address</Form.Label>
                    <Form.Control id='customerAddress' type='text' value={address} onChange={updateInputValue} />
                </FormGroup>
                <FormGroup className='wcc-form-group col-3'>
                    <Form.Label>SR Number</Form.Label>
                    <Form.Control id='srNumber' type='number' value={srNumber} onChange={updateInputValue} />
                </FormGroup>
            </Row>
            <Row className='button-group'>
                <Button variant='outline-primary' onClick={clearSearchResults}>
                    Clear
                </Button>
                <Button onClick={searchSrItem}>Search</Button>
            </Row>
            <PopUpModal modalStatus={modalShow} onHide={() => setModalShow(false)} messageHeader={modalHeader} />
        </div>
    );
}
