import { useEffect, useState, ChangeEvent } from 'react';
import { FormGroup, Row, Col, Form } from 'react-bootstrap';
import { DogAndOwnerDetailsModel, OwnerAndDogsDetailsModel, OwnerDetails } from '../../models/DogAndOwnerModel';
import { DogOwnerInfo } from '../../models/Dto/dogOwnerInfoDto';
import SrResult from '../../models/SrResult';
import eventBus from '../../utils/eventBus';
import { format, subYears } from 'date-fns';
import CustomerDetailsModel from '../../models/CustomerDetails';

interface CustomerDetailsProps {
    reRegDogOwner?: DogOwnerInfo | undefined;
    dogAndOwnerModel?: DogAndOwnerDetailsModel;
    ownerAndDogsDetails?: OwnerAndDogsDetailsModel;
    customerInfo?: CustomerDetailsModel;
    selectedSr?: SrResult;
    emitCustomerDetails?(customer: OwnerDetails): void;
}

export default function CustomerDetails(props: Readonly<CustomerDetailsProps>) {
    const maxYear = format(subYears(new Date(), 16), 'yyyy-MM-dd');

    const [formStatus, setFormStatus] = useState(false);
    const [title, setTitle] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [poBox, setPoBox] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [email, setEmail] = useState('');
    const [ownerSr, setOwnerSr] = useState(0);
    const [customerNo, setCustomerNo] = useState(0);

    const updateSelectElement = (el: ChangeEvent<HTMLSelectElement>) => {
        setTitle(el.currentTarget.value);
        emitUpdatedCustomerDetails(el);
    };

    const updateInputElement = (el: ChangeEvent<HTMLInputElement>) => {
        switch (el.currentTarget.id) {
            case 'firstName':
                setFirstName(el.currentTarget.value);
                emitUpdatedCustomerDetails(el);
                break;
            case 'lastName':
                setLastName(el.currentTarget.value);
                emitUpdatedCustomerDetails(el);
                break;
            case 'address':
                setAddress(el.currentTarget.value);
                emitUpdatedCustomerDetails(el);
                break;
            case 'poBox':
                setPoBox(el.currentTarget.value);
                emitUpdatedCustomerDetails(el);
                break;
            case 'dateOfBirth':
                setDateOfBirth(el.currentTarget.value);
                emitUpdatedCustomerDetails(el);
                break;
            case 'email':
                setEmail(el.currentTarget.value); // todo: do email validation later
                emitUpdatedCustomerDetails(el);
                break;
            default:
                break;
        }
    };

    const emitUpdatedCustomerDetails = (el: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        let customerDetails: OwnerDetails = {
            title: title,
            givenName: firstName,
            lastName: lastName,
            fullAddress: address,
            dob: dateOfBirth,
            email: email,
            ownerSr: ownerSr,
            customerNo: customerNo,
        };
        switch (el.currentTarget.id) {
            case 'title':
                customerDetails.title = el.currentTarget.value;
                eventBus.dispatch('customerDetailsUpdated', customerDetails);
                break;
            case 'firstName':
                customerDetails.givenName = el.currentTarget.value;
                eventBus.dispatch('customerDetailsUpdated', customerDetails);
                break;
            case 'lastName':
                customerDetails.lastName = el.currentTarget.value;
                eventBus.dispatch('customerDetailsUpdated', customerDetails);
                break;
            case 'address':
                customerDetails.fullAddress = el.currentTarget.value;
                eventBus.dispatch('customerDetailsUpdated', customerDetails);
                break;
            case 'dateOfBirth':
                customerDetails.dob = el.currentTarget.value;
                eventBus.dispatch('customerDetailsUpdated', customerDetails);
                break;
            case 'email':
                customerDetails.email = el.currentTarget.value;
                eventBus.dispatch('customerDetailsUpdated', customerDetails);
                break;
        }
    };

    const setupCustomerDetails = () => {
        if (props.dogAndOwnerModel) {
            setFirstName(props.dogAndOwnerModel?.owner.givenName || '');
            setLastName(props.dogAndOwnerModel?.owner.lastName || '');
            setAddress(props.dogAndOwnerModel?.owner.fullAddress || '');
            setDateOfBirth(props.dogAndOwnerModel?.owner.dob || '');
            setOwnerSr(props.dogAndOwnerModel.owner.ownerSr);
            setCustomerNo(props.dogAndOwnerModel.owner.customerNo);
        } else if (props.ownerAndDogsDetails) {
            setFirstName(props.ownerAndDogsDetails?.owner.givenName || '');
            setLastName(props.ownerAndDogsDetails?.owner.lastName || '');
            setAddress(props.ownerAndDogsDetails?.owner.fullAddress || '');
            setDateOfBirth(props.ownerAndDogsDetails?.owner.dob || '');
            setOwnerSr(props.ownerAndDogsDetails.owner.ownerSr);
            setCustomerNo(props.ownerAndDogsDetails.owner.customerNo);
        } else if (props.customerInfo) {
            const lastNameIndex = props.customerInfo.CustomerName?.lastIndexOf(' ');
            const firstName = props.customerInfo.CustomerName!.slice(0, lastNameIndex);
            const lastName = props.customerInfo.CustomerName!.slice(lastNameIndex);
            setFirstName(firstName || '');
            setLastName(lastName || '');
            setAddress(props.customerInfo.NZUrban || props.customerInfo.NZRural || '');
            setPoBox(props.customerInfo.NZPOBox || '');
            setDateOfBirth(props.customerInfo.DateOfBirth || '');
            setEmail(props.customerInfo.EMail || props.customerInfo.Email || '');
        } else if (props.selectedSr) {
            const firstName = props.selectedSr.CustomerName?.slice(0, props.selectedSr.CustomerName?.indexOf(' '));
            setFirstName(firstName || '');
            const surname = props.selectedSr.CustomerName?.slice(props.selectedSr.CustomerName.indexOf(' ') + 1);
            setLastName(surname || '');
            setAddress(props.selectedSr.Address || props.selectedSr.PostalAddress || '');
        }
        if (props.reRegDogOwner) {
            setEmail(props.reRegDogOwner?.emailId || '');
        }
    };

    useEffect(() => {
        setupCustomerDetails();
        eventBus.on('isCreated', (formStatus: boolean) => {
            setFormStatus(formStatus);
        });
        return () => {
            eventBus.remove('isCreated');
        };
    }, []);

    return (
        <Row>
            <h5 style={{ fontWeight: '700', marginBottom: '1rem' }}>{props.selectedSr?.SrType === 'Dog Owner' ? 'Dog Owner Details' : 'Customer Details'}</h5>
            <Col>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>Title</Form.Label>
                    <Form.Select id='title' aria-label='Select a title' value={title} onChange={updateSelectElement} disabled={formStatus}>
                        <option value=''>Please select a title</option>
                        <option value='Mr'>Mr</option>
                        <option value='Mrs'>Mrs</option>
                        <option value='Miss'>Miss</option>
                        <option value='Ms'>Ms</option>
                        <option value='Dr'>Dr</option>
                    </Form.Select>
                </FormGroup>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control id='firstName' type='text' value={firstName} onChange={updateInputElement} disabled={formStatus} />
                </FormGroup>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control id='lastName' type='text' value={lastName} onChange={updateInputElement} disabled={formStatus} />
                </FormGroup>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>NZ Urban Address</Form.Label>
                    <Form.Control id='address' type='text' value={address} onChange={updateInputElement} disabled={formStatus} />
                </FormGroup>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>PO Box</Form.Label>
                    <Form.Control id='poBox' type='text' value={poBox} onChange={updateInputElement} disabled={formStatus} />
                </FormGroup>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control id='dateOfBirth' type='date' max={maxYear} value={dateOfBirth} onChange={updateInputElement} disabled={formStatus} />
                </FormGroup>
                <FormGroup className='wcc-form-group'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control id='email' type='text' value={email} onChange={updateInputElement} autoComplete='off' disabled={formStatus} />
                </FormGroup>
            </Col>
        </Row>
    );
}
